
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import { emptyToLine, timeFormat } from "@/utils/common";
import t from "@common/src/i18n/t";
import dayjs from "dayjs";
const user = namespace("user");
const management = namespace("management");
const app = namespace("app");

@Component({
  methods: { timeFormat },
  components: { FileUpload }
})
export default class managementEdit extends Vue {
  @management.Action getApplyInfo;
  @management.Action sentApply;
  @management.Action getSendRecords;
  @management.Action getStoreInfo;
  @management.Action updateStoreApply;
  @management.Action closeTmall;
  @management.Action openChannel;
  @user.State userData;
  dayjs = dayjs;
  emptyToLine = emptyToLine;
  @Prop({ default: null })
  storeData: any;
  willChangeLicenseId: string = "";
  willChangeIdNumber: string = "";
  willChangeIdMobileNUmber: string = "";
  showChangeLicenseId: boolean = false;
  showChangeIdNumber: boolean = false;
  showChangeMobile: boolean = false;
  dialogcodeform: boolean = false;
  dialogRecordTable: boolean = false;
  showOfflineTmall: boolean = false;
  codeform: any = {
    user: "",
    mobile: "",
    remark: ""
  };
  recoedData: any = [];
  Coderules = {
    user: [{ required: true, message: t("v210831.please-enter-the-applicant-name"), trigger: "blur" }],
    mobile: [
      { required: true, message: t("v210831.please-enter-the-applicant-mobile"), trigger: "blur" },
      { min: 0, max: 11, message: t("v210831.the-length-is-11"), trigger: "blur" }
    ],
    remark: [
      { required: true, message: t("v210831.please-enter-the-apply-for-cause"), trigger: "blur" },
      { min: 0, max: 50, message: t("v210831.0-to-50-length"), trigger: "blur" }
    ]
  };
  treeProps = {
    label: "resourceName"
  };
  get breadData() {
    if (this.$route.name === "ManagementStoreView") {
      return [{ name: t("store.store-info"), path: "/management/store-list" }, { name: t("v210831.store-details") }];
    }
    return [
      { name: t("v210831.store-application"), path: "/management/apply-list" },
      { name: t("v210831.store-details") }
    ];
  }
  get $storeData() {
    return this.storeData || this.storeFormData;
  }
  allowEditStatus: any = ["40", "50", "80"];
  get showEditInfoBtn() {
    return (
      this.$route.name === "ManagementApplyView" && this.allowEditStatus.includes(this.storeFormData.tmallApplyStatus)
    );
  }
  storeFormData = {
    storeTypeDesc: "",
    shopK2Name: "",
    starLevel: 0,
    shopPickup: 1,
    growthValue: "0",
    invioce: "",
    storeWorkStations: "",
    contactsName: "",
    contactsPhone: "",
    servicePhone: "",
    serviceTel: "",
    province: "",
    city: "",
    district: "",
    address: "",
    longitude: "",
    latitude: "",
    storeFrontImage: "",
    //
    idcardBackImage: "",
    idcardFrontImage: "",
    k2Name: "",
    k2NumberImage: "",
    legalRepresentative: "",
    legalRepresentativeIdcard: "",
    licenseId: "",
    //
    companyPropertyValue: "",
    payeeAccount: "",
    payeeAddress: "",
    payeeBank: "",
    payeeBankNumber: "",
    payeeName: "",
    payeeSubBank: "",
    taxAddress: "",
    taxBank: "",
    taxBankAccount: "",
    taxCompanyName: "",
    taxPayerIdnumber: "",
    taxPhone: "",
    //
    tmallVerifyResult: "",
    tmallApplyStatus: "",
    openChannelList: []
  };
  tenantCode: any = "";
  orgCode: any = "";
  showMobileUsedTips: boolean = false;
  get pageType() {
    return this.$route.name === "ManagementStoreOpen" || this.$route.name === "ManagementApplyView" ? "open" : "view";
  }
  get applyCode() {
    return this.$route.params.id;
  }
  storeFormRules = {};
  sentapply(ruleForm) {
    (this.$refs.ruleForm as any).validate(valid => {
      if (valid) {
        this.sentApply({
          ...this.codeform,
          tenantCode: (this.storeFormData as any).tenantCode,
          orgCode: (this.storeFormData as any).orgCode
        }).then(data => {
          this.dialogcodeform = false;
          this.$message.success(t("v210831.the-temporary-password"));
          (this.$refs.ruleForm as any).resetFields();
        });
      } else {
        console.log("error submit!!");
      }
    });
  }
  handleMoreCommand(command) {
    if (command === "offlineTmall") {
      this.showOfflineTmall = true;
    } else if (command === "SendRecords") {
      this.querySendRecords();
    }
  }
  handleOfflineTmall() {
    this.closeTmall({
      applyCode: this.$storeData.applyCode
    }).then(data => {
      this.$message.success(t("base.success"));
      this.getStoreData();
      this.showOfflineTmall = false;
    });
  }
  created() {
    if (this.$route.name === "ManagementStoreView") {
      this.getStoreData();
    } else if (this.$route.name === "ManagementApplyView") {
      this.getApplyData();
    }
    this.codeform.user = this.userData.firstName;
    this.codeform.mobile = this.userData.mobile;
  }
  getStoreData() {
    this.getStoreInfo({
      storeCode: this.applyCode
    }).then(data => {
      Object.assign(this.storeFormData, data.data);
      this.tenantCode = data.data.tenantCode;
      this.orgCode = data.data.orgCode;
    });
  }
  querySendRecords() {
    this.dialogRecordTable = true;
    this.getSendRecords({
      tenantCode: this.tenantCode,
      orgCode: this.orgCode,
      pageNum: 1,
      pageSize: 1000
    }).then(data => {
      this.recoedData = data.data.list;
    });
  }
  handleoShowChangeLicenseId() {
    this.showChangeLicenseId = true;
  }
  handleoShowChangeMobile() {
    this.showChangeMobile = true;
  }
  handleShowChangeId() {
    this.showChangeIdNumber = true;
  }
  handleoCnfirmChangeInfo(type) {
    if (type === "licenseId") {
      if (!this.willChangeLicenseId) {
        return this.$message.error(t("v210831.please-enter-social"));
      }
      this.updateStoreApply({
        applyCode: this.applyCode,
        licenseId: this.willChangeLicenseId,
        legalRepresentativeIdcard: null
      }).then(data => {
        this.showChangeLicenseId = false;
        this.$message.success(t("v210831.modified-successfully"));
        this.getApplyData();
      });
    } else if (type === "idNumber") {
      if (!this.willChangeIdNumber) {
        return this.$message.error(t("v210831.please-enter-your"));
      }
      this.updateStoreApply({
        applyCode: this.applyCode,
        legalRepresentativeIdcard: this.willChangeIdNumber,
        licenseId: null
      }).then(data => {
        this.showChangeIdNumber = false;
        this.$message.success(t("v210831.modified-successfully"));
        this.getApplyData();
      });
    } else {
      if (!this.willChangeIdMobileNUmber) {
        return this.$message.error(t("v210831.please-enter-your"));
      }
      this.updateStoreApply({
        applyCode: this.applyCode,
        legalRepresentativePhone: this.willChangeIdMobileNUmber
      }).then(data => {
        this.showChangeMobile = false;
        this.$message.success(t("v210831.modified-successfully"));
        this.getApplyData();
      });
    }
  }
  getApplyData() {
    this.getApplyInfo({
      applyCode: this.applyCode
    }).then(data => {
      this.storeFormData = Object.assign({}, this.storeFormData, data.data);
    });
  }
}
